<template>
  <CenterContainer class="booking-details-page">
    <div class="pr-6 pl-6">
      <div v-if="baseBooking" >
        <InfoCard
          :headline="
            isCarOfferSelected
              ? $t('bookingDetailsPage.headlineWhenCarIsSelected')
              : $t('bookingDetailsPage.headlineWhenCarIsAvailable')
          "
          icon-name="check-circle"
          :message="$t('bookingDetailsPage.message')"
        />

        <BookingSummaryBox
          :booking-summary="baseBooking"
          :isMonthlyPrice="isMonthlyPrice()"
          :monthlyPrice="calculationResults.totalMonthlyPrice"
          :booking-days="calculationResults.bookingDays"
          :included-kms="calculationResults.includedKms"
          :price-per-additional-km="calculationResults.pricePerAdditionalKm"
          :price="calculationResults.totalPrice"
          :deductible="calculationResults.deductible"
          :show-booking-options="false"
        />

        <!-- Handover/Return times -->
        <v-row
          class="mt-5 handover-times"
          dense
        >
          <v-col cols="6">
            <v-select
              v-model="detailsForm.handoverTimeId"
              :items="allHandoverItems"
              :item-disabled="isHandoverItemDisabled"
              :item-value="'id'"
              :menu-props="{ offsetY: true }"
              :label="$t('bookingDetailsPage.dropdown.pickUpTime')"
              required
              outlined
              dense
              class="mobile-font-size"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                {{ $t("general.pickUpOption." + data.item.id) }}
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                {{ $t("general.pickUpOption." + data.item.id) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="detailsForm.returnTimeId"
              :items="clientConfig.pickUpOptions"
              :item-disabled="isReturnItemDisabled"
              :item-value="'id'"
              :menu-props="{ offsetY: true }"
              :label="$t('bookingDetailsPage.dropdown.returnTime')"
              required
              outlined
              dense
              class="mobile-font-size"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                {{ $t("general.pickUpOption." + data.item.id) }}
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                {{ $t("general.pickUpOption." + data.item.id) }}
              </template>
            </v-select>
          </v-col>
        </v-row>

        <Alerts :alerts="alerts" />

        <v-row>
          <v-col class="mt-0 pt-0">
            <BookingOptionsList
              :isMonthlyPrice="isMonthlyPrice()"
              :selected-option-ids="detailsForm.bookingOptionIds"
              @update:addSelectedOptionId="addSelectedOptionId($event)"
              @update:removeSelectedOptionId="removeSelectedOptionId($event)"
            />
          </v-col>
        </v-row>

        <!-- Custom Note -->
        <v-row class="mt-0">
          <v-col>
            <v-textarea
              v-model="detailsForm.customNote"
              solo
              maxlength="900"
              counter="900"
              name="input-7-5"
              :label="$t('bookingDetailsPage.textbox.customNote')"
            />
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <PriceBox
              v-if="isBookable || loading"
              :is-monthly-price="isMonthlyPrice()"
              :total-price="isMonthlyPrice() ? calculationResults.totalMonthlyPrice : calculationResults.totalPrice"
              :total-days="isMonthlyPrice() ? month : calculationResults.bookingDays"
              :loading="loading"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <NavigationButtons
              :navigate-back-action="navigateBack"
              :navigate-forward-action="navigateForward"
              :forward-enabled="!loading"
              :forward-text-key="
                isBookable
                  ? 'general.button.forward'
                  : 'bookingDetailsPage.button.toLead'
              "
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </CenterContainer>
</template>

<script>
import BookingSummaryBox from "@/components/ui/BookingSummaryBox";
import NavigationButtons from "@/components/ui/NavigationButtons";
import CenterContainer from "@/components/ui/CenterContainer";
import InfoCard from "@/components/ui/InfoCard";
import BookingOptionsList from "./BookingOptionsList";
import { mapGetters, mapMutations, mapActions } from "vuex";
import PriceBox from "@/components/ui/PriceBox";
import Alerts from "@/components/ui/Alerts";
import axios from "axios";
import { router } from "@/router";

export default {
  components: {
    BookingSummaryBox,
    NavigationButtons,
    BookingOptionsList,
    PriceBox,
    CenterContainer,
    Alerts,
    InfoCard,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    detailsForm: {
      bookingOptionIds: [],
      handoverTimeId: null,
      returnTimeId: null,
    },
    valid: true,
    isCarOfferSelected: false,
    alerts: [],
    isBookable: false,
    loading: false,
    month: 28
  }),
  computed: {
    ...mapGetters({
      clientConfig: "clientConfig",
      baseBooking: "baseBooking",
      bookingDetails: "bookingDetails",
      lastGapSeen: "lastGapSeen",
      availableBookingOptions: "availableBookingOptions",
      offers: "offers",
      customNote: "customNote",
      calculationResults: "calculationResults",
      pickupTimes: "pickupTimes",
      gaps: "gaps"
    }),
    allHandoverItems() {
      return this.clientConfig.pickUpOptions.filter(x => !x.beforeBusinessHours);
    }
  },
  watch: {
    detailsForm: {
      immediate: false,
      deep: true,
      handler: "calculateVehicleRentPrice"
    },
  },
  async beforeMount() {
    if ((!this.baseBooking && !this.offers ) && this.lastGapSeen.modelId == null ) {
      router.navigateTo("vehicleSearch");
    } else {
      Object.assign(this.detailsForm, this.bookingDetails);
      this.detailsForm.customNote = this.customNote;
    }

    this.RESET_CALCULATION_RESULT_STATE();
    if (this.offers) {
      const selectedOffer = this.offers[this.$router.currentRoute.params.id];
      if (selectedOffer) {
        await this.UPDATE_BOOKING_DATA(selectedOffer);
        this.RESET_AVAILABILITY_STATE();
        this.RESET_ANY_CAR_STATE();
        this.RESET_SELECTED_PICKUP_TIMES();
        this.SET_AVAILABLE_PICKUP_TIMES(selectedOffer.pickupTimes);
        this.SAVE_SELECTION_FORM_DATA(this.baseBooking);
        this.isCarOfferSelected = true;
      }
    }
    
    if (this.gaps && this.lastGapSeen.modelId != null && !this.detailsForm.handoverTimeId) {
      var bookingData = {
        baseBooking: {
          endDate: this.lastGapSeen.endDate,
          equipmentLineId: this.lastGapSeen.equipmentLineId,
          modelId: this.lastGapSeen.modelId,
          belongsTo: this.lastGapSeen.belongsTo,
          modelVariantId:  this.lastGapSeen.modelVariantId,
          vehicleId: this.lastGapSeen.vehicleId,
          siteId:  this.lastGapSeen.siteId,
          siteName:  this.lastGapSeen.siteName,
          startDate:  this.lastGapSeen.startDate,
        },
        bookingDetails: {
          bookingOptionIds: this.lastGapSeen.bookingOptionIds,
          handoverTimeId:  this.lastGapSeen.handoverTimeId,
          returnTimeId: this.lastGapSeen.returnTimeId,
        },
        pickupTimes: {
          availableHandoverIds:  this.lastGapSeen.availableHandoverIds,
          availableReturnIds:  this.lastGapSeen.availableReturnIds,
          defaultHandoverTimeId:  this.lastGapSeen.defaultHandoverTimeId,
          defaultReturnTimeId:  this.lastGapSeen.defaultReturnTimeId,
        }
      }
      await this.UPDATE_BOOKING_DATA(bookingData);
      this.RESET_AVAILABILITY_STATE();
      this.RESET_SELECTED_PICKUP_TIMES();
      this.SET_AVAILABLE_PICKUP_TIMES(bookingData.pickupTimes);
      this.SAVE_SELECTION_FORM_DATA(bookingData.baseBooking);
      this.isCarOfferSelected = true;
      this.detailsForm.handoverTimeId = bookingData.pickupTimes.defaultHandoverTimeId
      this.detailsForm.returnTimeId = bookingData.pickupTimes.defaultReturnTimeId

    }
    if (!this.detailsForm.handoverTimeId && this.lastGapSeen.modelId == null) {
      this.detailsForm.handoverTimeId = this.pickupTimes.defaultHandoverTimeId
    }
    if (!this.detailsForm.returnTimeId && this.lastGapSeen.modelId == null) {
      this.detailsForm.returnTimeId = this.pickupTimes.defaultReturnTimeId
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.UPDATE_BOOKING_DETAILS(this.detailsForm);
   
    next();
  },
  methods: {
    ...mapMutations({
      RESET_AVAILABILITY_STATE: "RESET_AVAILABILITY_STATE",
      RESET_ANY_CAR_STATE: "RESET_ANY_CAR_STATE",
      UPDATE_ERROR: "UPDATE_ERROR",
      RESET_CALCULATION_RESULT_STATE: "RESET_CALCULATION_RESULT_STATE",
      SET_AVAILABLE_PICKUP_TIMES: "SET_AVAILABLE_PICKUP_TIMES",
      RESET_SELECTED_PICKUP_TIMES: "RESET_SELECTED_PICKUP_TIMES",
      SET_LAST_GAP_SEEN: "SET_LAST_GAP_SEEN"
    }),
    ...mapActions({
      UPDATE_BOOKING_DETAILS: "UPDATE_BOOKING_DETAILS",
      UPDATE_BOOKING_DATA: "UPDATE_BOOKING_DATA",
      UPDATE_CALCULATION_RESULTS: "UPDATE_CALCULATION_RESULTS",
      SAVE_SELECTION_FORM_DATA: "SAVE_SELECTION_FORM_DATA",
    }),
    navigateBack() {
      if(this.lastGapSeen.modelId !=  null){
        router.navigateTo("gaps")
      }else{
        router.navigateTo("vehicleSearch");
      }
      
    },
    navigateForward() {
      if (this.isBookable) {
        router.navigateTo("bookingContact");
      } else {
        router.navigateTo("leadCustomNote");
      }
    },
    addSelectedOptionId(optionId) {
      if (!this.detailsForm.bookingOptionIds.includes(optionId)) {
        this.detailsForm.bookingOptionIds.push(optionId);
      }
    },
    removeSelectedOptionId(optionId) {
      if (this.detailsForm.bookingOptionIds) {
        this.detailsForm.bookingOptionIds =
          this.detailsForm.bookingOptionIds.filter((x) => x != optionId);
      }
    },
    async calculateVehicleRentPrice() {
      this.loading = true;

      try {
        var bookingData = {
          booking: {
            startDate: this.baseBooking.startDate,
            endDate: this.baseBooking.endDate,
            belongsTo: this.baseBooking.belongsTo,
            modelId: this.baseBooking.modelId,
            vehicleId: this.baseBooking.vehicleId,
            siteId: this.baseBooking.siteId,
            modelVariantId: this.$router.currentRoute.params.modelVariantId ?? "",
            equipmentLineId: this.baseBooking.equipmentLineId,
           
          },
          bookingDetails: {
            bookingOptionIds: this.detailsForm.bookingOptionIds,
            handoverTimeId: this.detailsForm.handoverTimeId,
            returnTimeId: this.detailsForm.returnTimeId,
          },
          discount: this.lastGapSeen.modelId != null
        };
        const res = await axios.post(`/booking/calcPrice`, bookingData);
        this.UPDATE_CALCULATION_RESULTS(res.data);
        this.loading = false;
        this.alerts = res.data.alerts;
        this.isBookable = res.data.isBookable;
        this.setCompulsoryItems()
      } catch (err) {
        if (!axios.isCancel(err)) {
          this.UPDATE_ERROR({ error: err });
        }
      }
    },
    isHandoverItemDisabled(item) {
      return !this.pickupTimes.availableHandoverIds?.includes(item.id);
    },
    isReturnItemDisabled(item) {
      return !this.pickupTimes.availableReturnIds?.includes(item.id);
    },
    setCompulsoryItems() {
      this.availableBookingOptions.forEach((bookingOption) => {
        if(bookingOption.selectables[0].isMandatory){
          this.addSelectedOptionId(bookingOption.selectables[0].bookingOptionId)
        }
      })
    },
    isMonthlyPrice() {
      var twoMonths = 56;
      if (this.calculationResults.bookingDays >= twoMonths){
        return true;
      }
      return false;
    },
  },
};
</script>

<style src="@/styles/pages/BookingDetailsPage.scss" lang="scss">

</style>
