import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import { router } from "./router";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import store from "./store/store";

import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";
import { faCarBattery } from "@fortawesome/free-solid-svg-icons/faCarBattery";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faCar } from "@fortawesome/free-solid-svg-icons/faCar";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { faHouseLock } from "@fortawesome/free-solid-svg-icons/faHouseLock";
import { faHandHolding } from "@fortawesome/free-solid-svg-icons/faHandHolding";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons/faCircleArrowRight";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons/faCircleArrowDown";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons/faShieldHalved";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faChildReaching } from "@fortawesome/free-solid-svg-icons/faChildReaching";


import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VCalendar, {
  componentPrefix: "vc",
  screens: {
    "sm": "640px",  // (min-width: 640px)
    "md": "720px",  // (min-width: 768px)
    "lg": "1024px", // (min-width: 1024px)
    "xl": "1280px"  // (min-width: 1280px)
  }
});

if (window.apiEndpoint) {
  axios.defaults.baseURL= window.apiEndpoint + "/api/v1/";
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT + "/api/v1/";
}

Vue.config.productionTip = false;

library.add(
  faSlidersH,
  faCarBattery,
  faCalendarAlt,
  faMapMarkerAlt,
  faCar,
  faArrowAltCircleRight,
  faInfoCircle,
  faTimesCircle,
  faCheckCircle,
  faRotate,
  faHouseLock,
  faCircleXmark,
  faHouseLock,
  faHandHolding,
  faCircle,
  faCircleCheck,
  faExclamationTriangle,
  faCircleArrowRight,
  faCircleArrowDown,
  faRoad,
  faShieldHalved,
  faUserPlus,
  faChildReaching,
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon );
Vue.component('FontAwesomeLayers', FontAwesomeLayers)

export const bus = new Vue();

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
